import React from 'react'
import styled from 'styled-components'

const Youtube = () => (
  <VideoContainer>
    <IFrame
      width="100%"
      id="youtube"
      src="https://www.youtube.com/embed/-FndqBrEcK4"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </VideoContainer>
)

export default Youtube

const VideoContainer = styled.div``

const IFrame = styled.iframe`
  border: 0;
  height: calc(100vw / 1.77);
  max-height: 600px;
`

import React from 'react'
import styled from 'styled-components'
import { Container, device } from './base'
import Birds from './Birds'
import CountDown from './CountDown'

export default function Banner() {
  return (
    <BannerContainer>
      <BirdsContainer>
        <Birds />
      </BirdsContainer>

      <TimerContainer>
        <h1>
          Syam <br /> &amp; <br />
          Pavithra
        </h1>
        <span>About to start their journey together</span>
        {/* <CountDownContainer>
          <CountDown
            date={new Date('Sun Feb 06 2022 11:30:00 GMT+0530 (IST)')}
          />
        </CountDownContainer> */}
        <ButtonContainer>
          <Button href="#youtube">Watch Live</Button>
        </ButtonContainer>
      </TimerContainer>
    </BannerContainer>
  )
}

const BannerContainer = styled(Container)`
  height: 100vh;
  background-image: url(${'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/e83f07cd-4bf7-4329-92d1-6df8ff4a6b70/d8d27ev-ee03210d-43c1-4df3-850e-d98693f7d799.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2U4M2YwN2NkLTRiZjctNDMyOS05MmQxLTZkZjhmZjRhNmI3MFwvZDhkMjdldi1lZTAzMjEwZC00M2MxLTRkZjMtODUwZS1kOTg2OTNmN2Q3OTkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.L7X8sJcYfqViM3cmSnvfKcxWmBd1KAy3wSEFuiJRZy8'});
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (${device.sm}) {
    background-size: auto 100%;
  }
`

const TimerContainer = styled.div`
  background: rgb(0 0 0 / 60%);
  color: white;
  max-width: 500px;
  width: 90%;
  margin: auto;
  padding: 1rem;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 3px;

  @media screen and (min-width: 1000px) {
    padding: 2rem;
    max-width: 700px;
  }

  h1 {
    font-size: 5em;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    line-height: 1.3;
    margin: 0;
  }

  > span {
    font-size: 1rem;
    color: #ccc;
  }
`

const BirdsContainer = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  overflow: hidden;
`

const CountDownContainer = styled.div`
  font-size: 2rem;
  margin: 1rem 0rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  color: #fff;
`

const Button = styled.a`
  padding: 1rem 3rem;
  background: white;
  border-radius: 3rem;
  margin-top: 1.5rem;
  cursor: pointer;
  color: #2d112b;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

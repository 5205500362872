import React from 'react'
import styled, { css } from 'styled-components'
import '../scss/speachBubble.scss'

export default function SpeachBubble({ children, align = 'left' }) {
  return (
    <BubbleContainer align={align}>
      <Bubble className="bubble" className={align}>
        {children}
      </Bubble>
    </BubbleContainer>
  )
}

const BubbleContainer = styled.div`
  float: ${(props) => (props.align === 'right' ? 'right' : 'left')};
`

const Bubble = styled.div`
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
  mix-blend-mode: color-dodge;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 1em;
  color: #774f38;
  background-color: #ece5ce;
  padding: 1rem;
  border-radius: 11px;
  position: relative;
  box-shadow: 20px 20px #83af9b;
  text-align: left;
  font-family: 'Nunito', sans-serif;

  &:after {
    transform: translatey(0px);
    animation: float2 5s ease-in-out infinite;
    content: '.';
    font-weight: bold;
    // -webkit-text-stroke: 0.5px #c8c8a9;
    -webkit-text-fill-color: #ece5ce;
    // border: 1px solid #c8c8a9;
    text-shadow: 22px 22px #83af9b;
    text-align: left;
    font-size: 55px;
    width: 55px;
    height: 11px;
    line-height: 30px;
    border-radius: 11px;
    background-color: #ece5ce;
    position: absolute;
    display: block;
    bottom: -30px;
    left: 0;
    box-shadow: 22px 22px #83af9b;
    z-index: -2;
  }

  &.right {
    box-shadow: -20px 20px #83af9b;
  }

  &.right:after {
    left: auto;
    right: 0;
    box-shadow: -22px 22px #83af9b;
    display: flex;
    justify-content: flex-end;
    text-shadow: -22px 22px #83af9b;
  }
`

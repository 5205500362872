import React from 'react'
import styled from 'styled-components'
import {
  Container,
  Heading,
  SideBySide,
  LeftSection,
  RightSection,
  SubHeading,
  Text,
  SecText,
  IFrame,
  Divider,
} from './base'

export default function Wedding() {
  return (
    <WeddingContainer>
      <Container>
        <Heading>Wedding Day</Heading>
        <Divider space={'1rem'} />
        <SideBySide>
          <LeftSection>
            <Text>Syam will tie the wedding knot on</Text>
            <Divider space={'1.5rem'} />
            <SubHeading>Date: 06 Feb 2022</SubHeading>
            <SubHeading>Time: 11.00 - 11:45</SubHeading>
            <SubHeading>
              Place: Pazhayannur Bhagavathi Temple, Next to Chittur kavu temple,
              Palakkad, Kerala.
            </SubHeading>
            <Divider space={'1rem'} />
            <SecText>
              Pavi's parents were married at Chittur Kavu temple and we wanted
              to get married there too. ❤️
              <Divider space={'1rem'} />
              With the blessings of family and friends, the wedding will be held
              in accordance with Hindu tradition
            </SecText>
          </LeftSection>
          <RightSection>
            <IFrame
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1960.199731438209!2d76.73774504412333!3d10.703640400000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba86b033fb14ae1%3A0x7243a8f5b60b6405!2sPazhayannur%20Bhagavathi%20Temple!5e0!3m2!1sen!2sde!4v1640096924708!5m2!1sen!2sde"
              height="350"
              width="100%"
              allowfullscreen={false}
              loading="lazy"
              title="wedding location"
            />
          </RightSection>
        </SideBySide>
      </Container>
      <Divider space={'4rem'} />
      <MoreDetails>
        <Text>
          After the traditional wedding at the temple, the newlyweds seek
          blessings from the Almighty and their parents. The wedding reception
          and other functions will be held at Meenakshi Mahal convention center,
          close to the temple.
        </Text>
      </MoreDetails>

      <Divider space={'4rem'} />
      <Container>
        <SideBySide>
          <LeftSection>
            <IFrame
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15680.182159752341!2d76.7256876!3d10.7309711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6e97bc3b6135b7!2sMeenakshi%20Mahal!5e0!3m2!1sen!2sde!4v1636671552297!5m2!1sen!2sde"
              height="350"
              width="100%"
              allowfullscreen={false}
              loading="lazy"
              title="wedding reception location"
            />
          </LeftSection>
          <RightSection>
            <Text>
              Following are the details of the wedding functions &amp; reception
              that will occur on the same day:
            </Text>
            <Divider space={'1.5rem'} />
            <SubHeading>Time: 11.30 onwards</SubHeading>
            <SubHeading>Place: Meenakshi Mahal, Polpully, Kerala.</SubHeading>
            <Divider space={'1.5rem'} />
            <SecText>
              We wanted the functions to be held in a much bigger place where we
              can all hangout, take pictures, and enjoy some amazing Palakadan
              Sadya at the same time.
              <br />
              <br />
              In Meenakshi Mahal, there is sufficient room for all of us and the
              extra space lends itself to greater safety and security which
              enables better social distancing.
            </SecText>
          </RightSection>
        </SideBySide>
        <Divider space={'2rem'} />

        <Text>
          New to Palakkad?{' '}
          <a
            href="https://www.tripadvisor.com/Attractions-g1096235-Activities-Palakkad_Palakkad_District_Kerala.html"
            target="_blank"
            rel="noreferrer"
          >
            Checkout the local attractions you can explore
          </a>
        </Text>
        <Divider space={'4rem'} />
      </Container>
    </WeddingContainer>
  )
}

const MoreDetails = styled(Container)`
  background-color: #2d112b;
  padding: 2.5rem 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 2px #f3d1d1;

  > p {
    max-width: 700px;
    width: 90%;
    color: white;
    margin: auto;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
  }
`

const WeddingContainer = styled.section`
  padding-top: 3rem;
  background: rgb(255 163 182 / 26%);
  h1 {
    margin-top: 0;
  }
`

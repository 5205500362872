import React from 'react'
import styled from 'styled-components'
import { Container, device, Divider, IFrame } from './base'

export default function Footer() {
  return (
    <FooterContainer>
      <Container className="flex-box">
        <div className="left">
          <p>
            Wedding day:{' '}
            <b>The groom's party starts at 5.15am from Syam's residence</b>
          </p>
          <p>
            Reception day:{' '}
            <b>
              The bus to the convention center starts at 11.45 am from Syam's
              residence
            </b>
          </p>
          <Divider space={'1rem'} />
          <IFrame
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31503.809490097374!2d76.5401334!3d9.2464257!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06192e10bd0a45%3A0x1191670ca8e008cf!2sKarthika!5e0!3m2!1sen!2sde!4v1636743311655!5m2!1sen!2sde"
            height="200"
            width="200"
            allowfullscreen={false}
            loading="lazy"
            title="reception location"
          />
        </div>
        <div className="right">
          Ph: <b>+91 9742 033616</b>
          <br />
          Whatsapp: <b>+49 1590 1534339</b>
          <br />
          Email: <b>hello@syamsp.com</b>
        </div>
      </Container>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  background-color: #2d112b;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding: 2.5rem 0rem;
  line-height: 2;
  font-size: 0.85em;

  p {
    margin: 0;
  }

  .left {
    text-align: left;
    @media screen and (${device.sm}) {
      text-align: center;
    }
  }

  .right {
    text-align: right;
    @media screen and (${device.sm}) {
      text-align: center;
    }
  }

  .flex-box {
    display: flex;
    justify-content: space-between;

    @media screen and (${device.sm}) {
      flex-direction: column;
      gap: 2rem;
      text-align: center;
    }
  }

  iframe {
    border: 5px solid #fff;
  }
`

import React from 'react'
import Countdown from 'react-countdown'

export default function CountDown({ date }) {
  return <Countdown date={date} renderer={renderer} />
}

// Renderer callback with condition
const renderer = ({ days, hours, minutes, completed }) => {
  if (completed) {
    // Render a completed state
    return null
  } else {
    // Render a countdown
    return <span>{days} days</span>
  }
}

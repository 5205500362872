import React from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
import Wedding from './components/Wedding'
import Reception from './components/Reception'
import Footer from './components/Footer'
import Introduction from './components/Introduction'
import Youtube from './components/Youtube'

import './App.css'

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <Youtube />
      <Introduction />
      <Wedding />
      <Reception />
      <Footer />
    </div>
  )
}

export default App

import React from 'react'
import {
  Container,
  Heading,
  SideBySide,
  LeftSection,
  RightSection,
  SubHeading,
  Text,
  SecText,
  IFrame,
  Divider,
} from './base'

export default function Reception() {
  return (
    <Container>
      <Divider space={'2rem'} />
      <Heading>Reception Day</Heading>
      <Divider space={'1rem'} />
      <SideBySide>
        <LeftSection>
          <IFrame
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15747.939329019748!2d76.5344208!3d9.3346089!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbe7ef08c9ffcae54!2sAnns%20Convention%20Centre%2CKadapra%20Thiruvalla!5e0!3m2!1sen!2sde!4v1636673184875!5m2!1sen!2sde"
            height="350"
            width="100%"
            allowfullscreen={false}
            loading="lazy"
            title="reception location"
          />
        </LeftSection>
        <RightSection>
          <Text>
            The newlyweds will travel to the groom's residence on the same day
            of the wedding. Since it's a long and tiresome journey, the
            reception at the groom's side is arranged the next day.
          </Text>
          <Divider space={'1.5rem'} />
          <SubHeading>Date: 07 Feb 2022</SubHeading>
          <SubHeading>Time: 12.30 - 17:30</SubHeading>
          <SubHeading>
            Place: Anns Convention Centre,Kadapra Thiruvalla.
          </SubHeading>
          <SubHeading>Dresscode: Anything you are comfortable with</SubHeading>
          <Divider space={'.3rem'} />
          <SecText className="no-margin">
            ( Please don't get too comfortable though 😜 )
          </SecText>
          <Divider space={'1.5rem'} />
          <SecText>
            The couple will cut the cake around 01:00pm and share with each
            other
            <br />
            The reception will also be conducted as per the covid protocols and
            the entire premisis will be sanitised. The long time frame allows
            you to drop by at any point to avoid crowd :)
          </SecText>
        </RightSection>
      </SideBySide>

      <Divider space={'1rem'} />

      <Text>
        New to Mavelikara/Alappuzha?&nbsp;
        <a
          href="https://www.tripadvisor.com/Attractions-g608471-Activities-Alappuzha_Alappuzha_District_Kerala.html"
          target="_blank"
          rel="noreferrer"
        >
          Checkout the local attractions you can explore
        </a>
      </Text>

      <Divider space={'4rem'} />
    </Container>
  )
}
